$(document).ready(function() {

	var nameCookie = "cookieControlPrefs";
	// var allCookie = ["essential"];
	var expiresCookies = Math.round(Math.min(Math.max(0, +30), 730)) || 0;

	// Ajouter un cookie
	var setCookie = function(name, value, expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + value + ";expires=" + d.toUTCString() + ";path=/";

        // MISE A JOUR DES PREFRENCES AU NIVEAU DE GOOGLE
        cookieControlPrefs = document.cookie.split('; ').find(row => row.startsWith('cookieControlPrefs='));

        if (cookieControlPrefs !== undefined) {
            cookieControlPrefs = cookieControlPrefs.split('=')[1];
            if (JSON.parse(cookieControlPrefs).find(element => element == "analytics") == "analytics") {
                // Consentement analytics
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage' : 'granted'
                });
            }
        }

        // Remove contenu du DOM
        $('#modal-cookie').modal('hide');
        $('#cookie').remove();

        return getCookie(name);
    };

    // Recuperer un cookie
    var getCookie = function(name) {
        var ca = decodeURIComponent(document.cookie).split(";");
        name += "=";
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    };

    // INITIALISATION DES COOKIES
    if (typeof getCookie('cookieControlPrefs') !== "undefined") {
    	$('#cookie').remove();
    } else {
    	$('#cookie').removeClass("d-none");
    }

    // PERMET DE FAIRE DEROULER LES INFOS
	$("[id^='cookies_accordion_title-']").on('click' , function() {
		id_cookies = this.id.split('-')[1];

		if ($(this).hasClass('active')) {
			$(this).removeClass('active');

			$('#cookies_accordion_contenu-'+id_cookies).removeClass('d-block').addClass('d-none');


		} else {
			$(this).addClass('active');

			$('#cookies_accordion_contenu-'+id_cookies).removeClass('d-none').addClass('d-block');
		}

		$(this).find('#cookies_accordion_extend').toggleClass('fa-plus fa-minus');


	});

	// CHANGE LE TOGGLE DES ACCEPTATIONS DES COOKIES
	$("[id^='cookies_accept-']").on('click' , function(e) {
		cookies_preference = this.id.split('-')[1];

		$(this).toggleClass('fa-toggle-on fa-toggle-off');

		var new_preference = $(this).attr('data-cookie-preference') == '' ? cookies_preference : '';
		$(this).attr('data-cookie-preference', new_preference);

		// NE LANCE PAS LA FONCTION PARENT
		e.stopPropagation();

	});

	// ACCEPTER L'ENSEMBLE DES COOKIES
	$("#cookie-button-accepter, #cookie-button-tout-accepter, #cookie-button-et-accepter").on('click', function() {
		var allCookie = ["essential"];

		$("[id^='cookies_accept-']").each(function(cookie) {
			allCookie.push(this.id.split('-')[1]);
		})


		setCookie(nameCookie, JSON.stringify(allCookie), expiresCookies);
	});

	// CONTINUER SANS ACCEPTER : AUCUN COOKIE A PART ESSENTIAL
	$("#cookie-button-sans-accepter").on('click', function() {
		var allCookie = ["essential"];
		setCookie(nameCookie, JSON.stringify(allCookie), expiresCookies);
	});

	// ENREGISTRER ET CONTINUER : CHOIX DES COOKIES
	$("#cookie-button-save").on("click", function() {
		var allCookie = ["essential"];

		$("[id^='cookies_accept-']").each(function(cookie) {
			allCookie.push($(this).attr('data-cookie-preference'));
		})

		// SUPPRIME TOUT LES VALEURS DU TABLEAU = ""
		allCookie = allCookie.filter(n => n);

		setCookie(nameCookie, JSON.stringify(allCookie), expiresCookies);
	})

});